import store from '../store/index'
import router from '../router/index'
import { post, get, put, del } from './myAxios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

// Official Website
export const postSeminar = async (formData) =>
    await post('/form/seminar', formData)

export const postJoin = async (formData) => await post('/form/join', formData)

// Donate System
export const createPaymentIntent = async (userData, amount) => {
    let donateCourse = ''
    if (cookies.isKey('donateCourse')) {
        donateCourse = cookies.get('donateCourse')
        cookies.remove('donateCourse')
    }
    return await post('/donate/createPaymentIntent', {
        userId: userData.userId || '',
        phone: userData.phone,
        name: userData.name,
        email: userData.email,
        subCategory: donateCourse,
        amount,
    })
}
export const updatePaymentStatus = async (clientSecret, status) =>
    await put('/donate/paymentStatus', { clientSecret, status })

// Member System
export const userLogin = async (email, password) => {
    let res = await post('/user/login', {
        email,
        password,
    })
    store.commit('updateUserData', res.data)
    let res2 = await get('/user/details')
    store.commit('updateUserData', res2.data)
    let res3 = await get('/course/all')
    store.commit('updateCourseStatus', res3)
    ElMessage.success('ログインに成功しました')

    setTimeout(() => {
        let serverRole = store.getters.getServerRole
        if (serverRole === 'admin') {
            router.push({ name: 'AdminMemberReview' })
        } else if (serverRole === 'user') {
            if (cookies.isKey('backRoutePath')) {
                let route = cookies.get('backRoutePath')
                cookies.remove('backRoutePath')
                router.push({ path: route })
            } else router.push({ name: 'CourseList' })
        } else {
            router.push({ name: 'Home' })
        }
    }, 1000)
}
export const checkEmail = async (email) =>
    await post('/user/isEmailExisted', { email })

export const userRegister = async (registerForm, userDetailForm) => {
    let payload = Object.assign(
        { email: registerForm.email, password: registerForm.password },
        userDetailForm
    )
    await post('/user/register', payload).then(() => {
        ElMessage.success(
            '登録に成功しました。認証のため、少々お待ちください。'
        )
        setTimeout(() => {
            router.push({ name: 'Home' })
        }, 1000)
    })
}
export const updateUserData = async (formData) => {
    let payload = {}
    let userState = store.getters.getUserState
    for (let key of Object.keys(formData)) {
        if (formData[key] !== userState[key]) {
            payload[key] = formData[key]
        }
    }
    payload.userId = formData.userId
    await put('/user/details', payload).then(() => {
        store.commit('updateUserData', payload)
        ElMessage.success('登録情報を更新しました。')
    })
}
export const updateUserPassword = async (oldPassword, newPassword) =>
    await put('/user/password', { oldPassword, newPassword })

export const forgetPassword = async (email) =>
    await post('/user/forgetPassword', { email })

export const isTokenValid = async (token) =>
    await post('/user/isTokenValid', { token })

export const updatePasswordFromForget = async (password, token) =>
    await post('/user/updatePasswordFromForget', { password, token })

export const getUserDonateDetail = async (sort, userId) => {
    let payload = {
        orderKey: sort.split(',')[0],
        order: sort.split(',')[1],
    }
    if (userId) payload.userId = userId
    return await get('/donate/details', payload)
}
export const updateDonateStatus = async () => {
    let res = await get('/donate/donatedList')
    store.commit('updateDonateStatus', res.data)
    return res
}

// Course System
/*
export const getBaseVideos = async () => {
    let payload = {
        resolution: store.getters.isPC ? '1280' : '720',
    }
    return await get('/course/base/videos', payload)
}
export const updateTheologySubCategory = async () => {
    let res = await get('/course/theology/category')
    store.commit('updateTheologySubcategory', res.data)
    return res
}
export const getTheologyVideos = async (subCategory) => {
    let payload = {
        resolution: store.getters.isPC ? '1280' : '720',
        subCategory: subCategory,
    }
    return await get('/course/theology/videos', payload)
}
*/
export const finishAllCourse = async () => {
    await post('/course/finished/level/5')
    ElMessageBox.alert(
        '「真御子宣誓」のお申し込みを受け付けました。<br/>後ほど事務局より折り返しご連絡させていただきます。',
        '真御子宣誓',
        {
            confirmButtonText: 'はい',
            center: true,
            dangerouslyUseHTMLString: true,
        }
    )
}
export const watchCourse = async (item) => {
    store.commit('watchCourse', item)
    await post('/course/watched', { courseId: item._id })
}
export const postCourse = async (formData) => {
    await post('/form/course/base/questionnaire', formData)
    if (
        formData.select1 === '「生命自覚宣誓の理」' &&
        formData.select2 === '申し込み'
    ) {
        await put('/user/details', { level: 2 })
        store.commit('updateUserData', { level: 2 })
    }
}
export const updateLevel = async (level) => {
    await put('/user/details', { level })
}

// Admin System
export const deleteUser = async (userId) => {
    console.log({ userId })
    await del('/user/', { userId })
    ElMessage.success('このユーザーは削除されました。')
    setTimeout(() => {
        router.push({ name: 'MemberOverview' })
    }, 1000)
}
export const getReviewMembers = async () => await get('/user/verifying')
export const updateVerifyMember = async (status, userId) =>
    await put('/user/verify', { status, userId })
export const getMemberOverview = async (routeQuery) => {
    return await get('/user/users', routeQuery)
}
export const getDonateOverview = async (routeQuery) => {
    return await get('/donate/records', routeQuery)
}
export const getMemberData = async (userId) =>
    await get('/user/details', { userId })
export const updateMemberData = async (formData, originData) => {
    let payload = {}
    for (let key of Object.keys(formData)) {
        if (formData[key] !== originData[key]) {
            payload[key] = formData[key]
        }
    }
    payload.userId = formData.userId
    await put('/user/details', payload).then(() => {
        ElMessage.success('登録情報を更新しました。')
    })
}
export const getMemberCourseRecord = async (sort, userId) => {
    let payload = {
        orderKey: sort.split(',')[0],
        order: sort.split(',')[1],
    }
    if (userId) payload.userId = userId
    return await get('/course/records', payload)
}
export const updateMemberCourseRecord = async (recordId, remark) =>
    await put('/course/remark', { recordId, remark })
